import React, { ReactNode } from 'react';
import { S } from './ControlStats.style';
import { GetClientOverviewStatisticsDto } from '@ansvar-sakerhet/api-client';
import { RenderCounterProps } from 'react-countup/build/types';
import CountUp from 'react-countup';

type Props = {
  stats: GetClientOverviewStatisticsDto | undefined;
  name: string;
};

export const ControlStats = ({ stats, name }: Props) => {
  if (!stats) return null;
  return (
    <S.StatsInner>
      <S.H6>Arbetsplatskontroller</S.H6>
      <S.Margin $bottom="m" />

      <CountUpPreset value={stats.controls.amount}>
        {({ countUpRef }) => (
          <div style={{ display: 'flex' }}>
            <S.H3>
              <span ref={countUpRef} /> kontroller
            </S.H3>
          </div>
        )}
      </CountUpPreset>
      <S.Margin $top="l" />
      {/* Ny tabell */}
      <S.Margin $top="m" />
      <S.Table>
        <S.THead>
          <tr>
            <th>Mest förekommande avvikelser</th>
            <th>{name}</th>
            <th>Kontrollerade projekt i landet</th>
          </tr>
        </S.THead>
        <S.TBody>
          <S.TBodyRow>
            <S.TBodyCell>
              <S.H6>Ej inloggade i EPL</S.H6>
            </S.TBodyCell>
            <S.TBodyCell>{stats.workControl.client.percentageOfAdditionalPersonnel}%</S.TBodyCell>
            <S.TBodyCell>{stats.workControl.all.percentageOfAdditionalPersonnel}%</S.TBodyCell>
          </S.TBodyRow>
          <S.TBodyRow>
            <S.TBodyCell>
              <S.H6>Brott mot ID06 regelverk</S.H6>
            </S.TBodyCell>
            <S.TBodyCell>{stats.workControl.client.percentageOfPersonnelWithIdCardDeviations}%</S.TBodyCell>
            <S.TBodyCell>{stats.workControl.all.percentageOfPersonnelWithIdCardDeviations}%</S.TBodyCell>
          </S.TBodyRow>
          <S.TBodyRow>
            <S.TBodyCell>
              <S.H6>Personlig säkerhetsutrustning</S.H6>
            </S.TBodyCell>
            <S.TBodyCell>{stats.workControl.client.percentageOfPersonnelWithoutSafetyEquipment}%</S.TBodyCell>
            <S.TBodyCell>{stats.workControl.all.percentageOfPersonnelWithoutSafetyEquipment}%</S.TBodyCell>
          </S.TBodyRow>
          <S.TBodyRow>
            <S.TBodyCell>
              <S.H6>Brott mot utstationeringslagen</S.H6>
            </S.TBodyCell>
            <S.TBodyCell>{stats.workControl.client.percentageOfPersonnelWithoutWorkPermit}%</S.TBodyCell>
            <S.TBodyCell>{stats.workControl.all.percentageOfPersonnelWithoutWorkPermit}%</S.TBodyCell>
          </S.TBodyRow>
          <S.TBodyRow>
            <S.TBodyCell>
              <S.H6>Förgodkända UE</S.H6>
            </S.TBodyCell>
            <S.TBodyCell>{stats.workControl.client.percentageOfAcceptedSubcontractors}%</S.TBodyCell>
            <S.TBodyCell>N/A</S.TBodyCell>
          </S.TBodyRow>
        </S.TBody>
      </S.Table>

      <S.Margin $top="xl" />
      <S.Margin $top="l" />
      <S.Flex
        $width={'100%'}
        $justify={'space-between'}
        $flexDir="row"
        $flexGrow={1}
      >
        <S.Box>
          <S.H6 $align="center">Ej åtgärdade avvikelser</S.H6>
          <S.Margin $top="m" />
          <S.ColorBox>
            <S.H3 style={{ margin: 'auto' }}>{stats.deviations.open} st</S.H3>
          </S.ColorBox>
        </S.Box>
        <S.Box>
          <S.H6 $align="center">Åtgärdade avvikelser</S.H6>
          <S.Margin $top="m" />
          <S.ColorBox>
            <S.H3 style={{ margin: 'auto' }}>{stats.deviations.closed} st</S.H3>
          </S.ColorBox>
        </S.Box>
      </S.Flex>
    </S.StatsInner>
  );
};

const CountUpPreset = ({
  children,
  value,
}: {
  children: (props: RenderCounterProps) => ReactNode;
  value: number;
}) => {
  return (
    <CountUp start={0} end={value} duration={3} delay={0}>
      {children}
    </CountUp>
  );
};
