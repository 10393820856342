import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';

export function useGetFrameCompaniesStats() {
  const getApi = useApi();

  const getFrameCompanies = async () => {
    const api = await getApi();
    const res = await api.frameCompanyGetStats();  
    console.log("res", res)
    return res;
  };

  const query = useQuery({
    queryKey: ['a'],
    queryFn: getFrameCompanies,
  });


  return {
    ...query,
    data: query.data?.data,
  };
}
