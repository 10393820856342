import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
// import { toast } from '../../../components/Toast';

export function useArchiveFrameCompany(companyId: string) {
    const getApi = useApi();
    const queryClient = useQueryClient();

    const mutationFn = async (archive: boolean) => {
        const api = await getApi();

        if(archive) {
            const res = await api.frameCompanyInactivateFrame(companyId);
            return res.data;
        } else {
            const res = await api.frameCompanyActivateFrame(companyId);
            return res.data;
        }
    };

    const onSuccess = () => {
        queryClient.invalidateQueries({ queryKey: keys.all });
    };

    const onError = () => {
        console.log("fel");
    };

    const mutation = useMutation({
        mutationFn,
        onSuccess,
        onError,
    });

    return mutation;
}
