const all = ['frame-company'];

const findAll = (take?: number, skip?: number, search?: string, responsible?: string, tags?: string[], accepted?: string, active?: boolean) => [
  ...all,
  take,
  skip,
  search,
  responsible,
  tags,
  accepted,
  active
];

const findOne = (newsId: string | undefined) => [...all, newsId];

export const keys = { all, findAll, findOne };
