import { UIElements } from '@ansvar-sakerhet/ui';
import { styled } from 'styled-components';
import { Input } from '../../../../../../components/Input';
import { Button as UIButton } from '@ansvar-sakerhet/ui/src/buttons/Button';

const Label = styled.label`
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 400;
`;

const Container = styled.div`
  width: 100%;
  max-width: 750px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const AnimatedIcon = styled(UIElements.Icon)`
  margin: 0px 6px 0px 0px;
  transition: transform 300ms;
`;

const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  padding: 1rem;
`;

const RecommendationHeading = styled.p`
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  margin: 0;
`;

const ContentText = styled.p`
  line-height: 20px;
  font-weight: 400;
  font-size: 16px;
  padding: 0;
  margin: 0;
`;

const ContentHeading = styled.p`
  font-size: 16px;
  padding-bottom: 0.4rem;
  font-weight: 500;
  margin: 0;
`;

const ResultsAndActionsSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

  div {
    padding-bottom: 1.5rem;
  }
`;

const CounterMeasuresSection = styled.div`
  padding-top: 1.5rem;
  & > div {
    padding-bottom: 1.5rem;
  }
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled(UIButton)`
  width: fit-content;
`;

const Trigger = styled.button<{ $isExpanded: boolean }>`
  all: unset;
  box-sizing: border-box;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  width: 100%;
  border-radius: 4px 4px
    ${({ $isExpanded }) => ($isExpanded ? '0px 0px' : '4px 4px')};
  border-bottom: ${({ $isExpanded, theme }) =>
    $isExpanded ? 'none' : `solid 1px ${theme.colors.gray}`};

  &:focus {
    outline: none;
  }

  ${AnimatedIcon} {
    transform: rotate(
      ${({ $isExpanded }) => ($isExpanded ? '0deg' : '180deg')}
    );
  }
`;

const ContentInput = styled(Input)`
  font-family: Helvetica Neue, sans-serif;
  padding: 0.8rem;
  font-size: 16px;
`;

export const S = {
  ...UIElements,
  Trigger,
  AnimatedIcon,
  Content,
  ContentHeading,
  ContentText,
  RecommendationHeading,
  ResultsAndActionsSection,
  CounterMeasuresSection,
  Container,
  ContentInput,
  Label,
  Button,
  FlexEnd,
};
